"use client"
import { useEffect, useState } from "react"
import { SessionProvider } from "next-auth/react"
import useMutation from "@src/hooks/use-mutation"
import { useSession } from "next-auth/react"
import { LOCAL_KEYS, LocalStorage } from "@src/utils/storages"


const FistTimeLoadAuth = ({ children }) => {
  const { status, update } = useSession()
  const { mutation } = useMutation()

  const [isInit, setIsInit] = useState(false)

  useEffect(() => {
    if (!isInit) {
      if (status === "authenticated" && !isInit && update) {
        update({
          type: "TRIGGER_API"
        })
        setIsInit(true)
      } else if (status === "unauthenticated") {
        const currentDeviceSession = LocalStorage.get(LOCAL_KEYS.DEVICE_SESSION)
        if (currentDeviceSession) {
          return
        }
        mutation({
          method: "POST",
          url: "/login-with-device",
          params: {
            device_token: currentDeviceSession?.device_token || "",
          },
          onSuccess: res => {
            if (res) {
              LocalStorage.set(LOCAL_KEYS.DEVICE_SESSION, {
                access_token: res?.access_token,
                auth_type: res?.user?.auth_type || "",
                device_token: res?.user?.device_token || "",
              })
            }
          },
        })
        setIsInit(true)
      }
    }
   
  }, [status, isInit])

  return children
}

export function AuthProvider({ children }) {
  return (
    <SessionProvider basePath="/server/auth">
      <FistTimeLoadAuth>{children}</FistTimeLoadAuth>
    </SessionProvider>
  )
}
